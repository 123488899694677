import { useEffect } from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useStoreConfig } from 'src/hooks/useStoreConfig'

function Page() {
  const { signUpUrl } = useStoreConfig()

  useEffect(() => {
    window.location.href = signUpUrl
  }, [signUpUrl])

  return (
    <>
      <GatsbySeo noindex nofollow />

      <div>loading...</div>
    </>
  )
}

export default Page
